import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Will you be in London on Tuesday, July 3?`}</p>
    <p>{`If so, come to the `}<a parentName="p" {...{
        "href": "https://connect.innovateuk.org/web/the-innovation-playground/event-details"
      }}>{`The Innovation Playground for Financial Services Summit`}</a>{`, a free conference organized in part by one of our investors, `}<a parentName="p" {...{
        "href": "http://www.anthemis.com/"
      }}>{`Anthemis Group`}</a>{`. Among the interesting presentations, Arlyn and I will be speaking about Education through Gamification in Finanicial Services, along with `}<a parentName="p" {...{
        "href": "http://uk.linkedin.com/in/amirsharif"
      }}>{`Amir Sharif`}</a>{`, a `}<a parentName="p" {...{
        "href": "http://www.brunel.ac.uk/bbs/people/academic-and-research-staff/full-time-faculty/amir-sharif"
      }}>{`professor and director`}</a>{` of MBA programmes at Brunel Business School.`}</p>
    <p>{`You can register `}<a parentName="p" {...{
        "href": "http://fs4.formsite.com/anthemis/innovation_playground/index.html"
      }}>{`here`}</a>{`.`}</p>
    {
      /* Innovation Playground for Financial Services Summit flyer img caption block */
    }
    <div {...{
      "className": "image-frame"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.75000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABvCKZOoMDn//EABwQAQACAQUAAAAAAAAAAAAAAAECAxIAEyEiM//aAAgBAQABBQKsyrl10vKu0ycafP8A/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEREv/aAAgBAwEBPwG2pf/EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AUn/xAAaEAACAgMAAAAAAAAAAAAAAAAAAQIhEBJB/9oACAEBAAY/AtsMhfBWxH//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMUFxwf/aAAgBAQABPyGkCF8XMYwW1aAFlBU7m8Ot+z6SmdmZ/9oADAMBAAIAAwAAABCIz//EABURAQEAAAAAAAAAAAAAAAAAABEQ/9oACAEDAQE/EEw//8QAFhEBAQEAAAAAAAAAAAAAAAAAACFR/9oACAECAQE/EBMf/8QAGxAAAgIDAQAAAAAAAAAAAAAAAREAITFBUWH/2gAIAQEAAT8QzYAhRHByXch+1AGGhMcqcIotnaAnDELbphE4iSFm9Cf/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/4cdfa206bea5eedbfe02498e33436c5d/7f61c/innovation-playground.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/4cdfa206bea5eedbfe02498e33436c5d/e88ff/innovation-playground.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/4cdfa206bea5eedbfe02498e33436c5d/066f9/innovation-playground.jpg 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/4cdfa206bea5eedbfe02498e33436c5d/b4294/innovation-playground.jpg 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/4cdfa206bea5eedbfe02498e33436c5d/b4294/innovation-playground.jpg",
            "alt": "Innovation Playground for Financial Services Summit",
            "title": "Innovation Playground for Financial Services Summit",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
  `}<span parentName="div" {...{
        "className": "caption-text"
      }}>{`We look forward to seeing you there!`}</span>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      